const checkboxes = [
    // Yorkshire puddings.
    {
        name: 'yorkshire-puddings',
        label: 'Yorkshire puddings',
        items: [
        {
            name: 'Make yorkshire batter',
            offset: 120,
            earlier: true,
        },
        {
            name: 'Put yorkshire tin in oven - 200C',
            offset: 40,
        },
        {
            name: 'Pour yorkshire batter in tray',
            offset: 25,
        },
        ]
    },
    // Roast lamb.
    {
        name: 'lamb-shoulder',
        label: 'Roast lamb shoulder',
        items: [
        {
            name: 'Get lamb out to rest',
            offset: 10,
        },
        {
            name: 'Reduce oven temp for lamb to 170C',
            offset: 90,
        },
        {
            name: 'Put lamb in oven in same pan - 190C',
            offset: 130,
        },
        {
            name: 'Sear lamb in pan 1 min each side',
            offset: 136,
        },
        {
            name: 'Preheat oven - 190C',
            offset: 140,
        },
        ]
    },
    // Roast chicken.
    {
        name: 'chicken-crown',
        label: 'Chicken crown',
        items: [
        {
            name: 'Get chicken out to rest',
            offset: 10,
        },
        {
            name: 'Remove chicken from bag',
            offset: 45,
        },
        {
            name: 'Put chicken into oven',
            offset: 85,
        },
        {
            name: 'Oven on for chicken',
            offset: 95,
        },
        ]
    },
    // Gravy.
    {
        name: 'gravy',
        label: 'Gravy (Instant)',
        items: [
        {
            name: 'Put kettle on to boil',
            offset: 7,
        },
        {
            name: 'Make instant gravy',
            offset: 3,
        },
        ]
    },
    // Peas.
    {
        name: 'peas',
        label: 'Peas',
        items: [
        {
            name: 'Put peas on to boil',
            offset: 5,
        },
        ]
    },
    // Carrot and parsnip mash.
    {
        name: 'carrot-parsnip-mash',
        label: 'Carrot and parsnip mash',
        items: [
        {
            name: 'Peel and chop carrots and parsnips',
            offset: 110,
            earlier: true,
        },
        {
            name: 'Boil carrots and parsnips',
            offset: 90,
            earlier: true,
        },
        {
            name: 'Drain carrots and parsnips and mash',
            offset: 75,
        },
        {
            name: 'Reheat carrots and parsnip mash',
            offset: 7,
        },
        ]
    },
    // Roast potatoes.
    {
        name: 'roast-potatoes',
        label: 'Roast potatoes',
        items: [
        {
            name: 'Peel potatoes',
            offset: 130,
            earlier: true,
        },
        {
            name: 'Parboil potatoes',
            offset: 110,
            earlier: true,
        },
        {
            name: 'Drain potatoes and fluff',
            offset: 100,
            earlier: true,
        },
        {
            name: 'Put Roast potatoes in oven - 180C',
            offset: 90,
        },
        {
            name: 'Oven on: 180C, tray with oil and rosemary',
            offset: 80,
        },
        ]
    },
    // Broccoli.
    {
        name: 'broccoli',
        label: 'Broccoli',
        items: [
        {
            name: 'Prepare broccoli',
            offset: 20,
            earlier: true
        },
        {
            name: 'Put broccoli on to boil',
            offset: 6
        },
        ]
    },
    // Leeks.
    {
        name: 'leeks',
        label: 'Leeks',
        items: [
        {
            name: 'Prepare leeks',
            offset: 30,
            earlier: true
        },
        {
            name: 'Start frying leeks on low heat',
            offset: 20
        },
        ]
    },
    // Carrots.
    {
        name: 'carrots',
        label: 'Carrots',
        items: [
        {
            name: 'Prepare carrots',
            offset: 20,
            earlier: true
        },
        {
            name: 'Put carrots on to boil',
            offset: 7
        },
        ]
    },
    // Stuffing.
    {
        name: 'stuffing',
        label: 'Stuffing',
        items: [
        {
            name: 'Prepare stuffing mix',
            offset: 40,
            earlier: true
        },
        {
            name: 'Bake stuffing',
            offset: 30
        },
        ]
    },
    // Pigs in blankets.
    {
        name: 'pigsinblankets',
        label: 'Pigs in blankets',
        items: [
        {
            name: 'Bake pigs in blankets',
            offset: 25
        },
        ]
    },
    // Roasted parsnip.
    {
        name: 'roast-parsnip',
        label: 'Roast parsnip',
        items: [
        {
            name: 'Peel parsnip',
            offset: 110,
            earlier: true,
        },
        {
            name: 'Put parnsip potatoes in oven - 180C',
            offset: 60,
        },
        ]
    },
    // Sprouts.
    {
        name: 'sprouts',
        label: 'Sprouts',
        items: [
        {
            name: 'Prepare sprouts',
            offset: 30,
            earlier: true
        },
        {
            name: 'Put sprouts on to boil',
            offset: 8
        },
        ]
    },
    // Roasted carrot.
    {
        name: 'roast-carrot',
        label: 'Roast carrot',
        items: [
            {
                name: 'Peel and chop carrots for roasting',
                offset: 40,
                earlier: true,
            },
            {
                name: 'Roast carrots at 180C',
                offset: 30,
            },
        ]
    },
    // Roasted topside of beef.
    {
        name: 'beef-topside',
        label: 'Beef Topside (1.252kg)',
        items: [    
            {
                name: 'Sear beef in pan 1 min each side',
                offset: 86,
            },
            {
                name: 'Oven on for beef: 180C',
                offset: 95,
            },
            {
                name: 'Put beef in at 180C',
                offset: 80
            },
            {
                name: 'Reduce beef temperature to 160C',
                offset: 60
            },
            {
                name: 'Get beef out to rest',
                offset: 15
            },
        ]
    },
  ];
  
  export default checkboxes;