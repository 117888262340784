import React from 'react';
import PropTypes from 'prop-types';
import checkboxes from './checkboxes';
import Checkbox from './Checkbox';
const moment = require('moment');

class CheckboxContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedItems: new Map(),
    }

    this.handleChange = this.handleChange.bind(this);
    this.targetTime = moment().startOf('day').hours(17).minutes(45);
    // If we are more than 30 minutes later than target time today, make it tomorrow.
    if (this.targetTime.isBefore(moment().add(30, 'minutes'))) {
        this.targetTime.add(1, 'day');
    }
    this.items = checkboxes.map(item => {
        return item.items.map(roastItem => {
            return {
                item: roastItem,
                name: item.name,
                time: this.targetTime.clone().subtract(roastItem.offset, 'minutes'),
                earlier: !!item.earlier,
            }
        });
    })
    .flat(1)
    .sort((a, b) => {
        return a.time.valueOf() - b.time.valueOf();
    });

  }

  

  handleChange(e) {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
  }

  render() {
    return (
        
      <React.Fragment>
          <div className="text-center mb-20">
          <div>
          <h1 className="fa-center text-5xl text-center mb-8 mt-4 border-b-4 border-indigo-700 inline-block">Target time</h1>
          </div>
          <p className="font-mono text-4xl text-indigo-700 ">{this.targetTime.format('hh:mm A')}</p>
          <div class="print:hidden">
          <h1 className="fa-center text-5xl text-center mb-8 mt-4 border-b-4 border-indigo-700 inline-block">Items</h1>
          </div>
          <ul class="mx-auto inline-block print:hidden">
        {
          checkboxes
          .sort((a, b) => {
              return a.label.localeCompare(b.label);
          })
          .map(item => (
            <li key={item.name} class="my-2">
              <label class="inline-flex items-center min-w-full">
              <Checkbox name={item.name} checked={this.state.checkedItems.get(item.name)} onChange={this.handleChange} />
              <span class="ml-2">{item.label}</span>
              </label>
            </li>
          ))
        }
        </ul>
        <div>
        <h1 className="fa-center text-5xl text-center mb-8 mt-4 border-b-4 border-indigo-700 inline-block">Timings</h1>
        <div></div>
        <ol class="inline-block mx-auto">
        {
          this.items.map(item => (
            <li key={item.item.name+item.time.format('HH:mm')}>
            {this.state.checkedItems.get(item.name) && 
              <div class="my-1 inline-flex items-center min-w-full">
                <span className="font-mono text-indigo-700 mr-2">
                  {item.time.format('hh:mm')}
                  {item.item.earlier && <span>*</span>}
                  {!item.item.earlier && <span>&nbsp;</span>}
                </span> {item.item.name} {false && item.time.isAfter() && <span className="font-mono text-indigo-700">{item.time.fromNow()}</span>}</div>
            }
            </li>
          ))
        }
        </ol>
        </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CheckboxContainer;