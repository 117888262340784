import * as React from "react"
import '../css/App.css';
import CheckboxContainer from "../components/Roast/CheckboxContainer"
const moment = require('moment');




// markup
const RoastDinnerPage = () => {

  return (
    <div>
      <CheckboxContainer></CheckboxContainer>
    </div>
  )
}

export default RoastDinnerPage
